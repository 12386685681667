const ArticlePage = async () =>
  await import('./article').then((page) => page.default);
const StartPage = async () =>
  await import('./start').then((page) => page.default);
const SectionStartPage = async () =>
  await import('./sectionstart').then((page) => page.default);
const CollectionPage = async () =>
  await import('./collection').then((page) => page.default);
const SearchPage = async () =>
  await import('./search').then((page) => page.default);
const FindCarePage = async () =>
  await import('./findcare').then((page) => page.default);
const ContactCardPage = async () =>
  await import('./contactcard').then((page) => page.default);
const ErrorPage = async () =>
  await import('./error').then((page) => page.default);

export const importPage = (pageTypeName) => {
  switch (pageTypeName) {
    case 'ArticlePage':
      return ArticlePage();
    case 'StartPage':
      return StartPage();
    case 'SectionStartPage':
      return SectionStartPage();
    case 'CollectionPage':
      return CollectionPage();
    case 'SearchPage':
      return SearchPage();
    case 'FindCarePage':
      return FindCarePage();
    case 'ContactCardPage':
      return ContactCardPage();
    case 'ErrorPage':
      return ErrorPage();
    default:
      return StartPage();
  }
};
