import React from 'react';
import * as Render from '../../platformui/Base/Render';
import CookiePanel from '../../platformui/CookiePanel';
import Header from '../../components/header';
import Footer from '../../components/footer';
import ScrollToTop from '../../platformui/ScrollToTop';
import AccessibilityNav from '../../components/accessibility-nav';
import SurveyEntry from '../../platformui/SurveyEntry';

const Layout = (props) => {
  const activeSurvey = props.SurveyEntries?.find((survey) => survey.IsActive);

  return (
    <div>
      {props.BuildInfo && <props.BuildInfoComponent {...props} />}
      <AccessibilityNav {...props} />
      <Header {...props} />
      <div className="main-wrapper">
        {activeSurvey && <SurveyEntry {...activeSurvey} />}
        <props.PageTypeRenderer key="asyncpages" {...props} />
        <ScrollToTop key="scrolltop" {...props} />
      </div>
      <Footer {...props} />
      {!props.CookiePro.Enabled && <CookiePanel {...props} />}
    </div>
  );
};

export default Render.Root(Layout);
